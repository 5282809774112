<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form ref="perForm" :model="bmsContractInfoDto" :rules="rules" label-width="120px" inline>
    
        <el-form-item label="合同标题" prop="title">
          <el-input v-model="bmsContractInfoDto.title" style="width: 100%" />
        </el-form-item>
        <el-form-item label="合同类别" prop="contractType">
          <el-input v-model="bmsContractInfoDto.contractType" style="width: 100%" />
        </el-form-item>
        <el-form-item label="合同编号" prop="contractNo">
          <el-input v-model="bmsContractInfoDto.contractNo" style="width: 100%" />
        </el-form-item>
        <el-form-item label="签订时间" prop="signDate">
          <el-date-picker
      v-model="bmsContractInfoDto.signDate"
      type="date"
      @change="selectChange1"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
      
    </el-date-picker>
        </el-form-item>
        <el-form-item label="签订人姓名" prop="signPerson">
          <el-input v-model="bmsContractInfoDto.signPerson" style="width: 100%" />
        </el-form-item>
        <el-form-item label="签订人类型" prop="signType">
          <el-input v-model="bmsContractInfoDto.signType" style="width: 100%" />
        </el-form-item>
        <el-form-item label="联系电话" prop="tel">
          <el-input v-model="bmsContractInfoDto.tel" style="width: 100%" />
        </el-form-item>
        <el-form-item label="签订地址" prop="address">
          <el-input v-model="bmsContractInfoDto.address" style="width: 100%" />
        </el-form-item>
        
        <el-form-item label="所属乡镇" prop="town">
          <el-input v-model="bmsContractInfoDto.town" style="width: 100%" />
        </el-form-item>
        <el-form-item label="订单数量" prop="orderNum">
          <el-input v-model="bmsContractInfoDto.orderNum" style="width: 100%" />
        </el-form-item>
        <el-form-item label="订单价格" prop="contractPrice">
          <el-input v-model="bmsContractInfoDto.contractPrice" style="width: 100%" />
        </el-form-item>
        <el-form-item   style=" visibility:hidden">
          <el-input  style=" visibility:hidden" />
        </el-form-item>
         <!-- 上传图片 -->
         <el-form-item label="合同图片" prop="fids">
            <el-upload
              class="upload-demo"
              :data="postData"
              list-type="picture-card"
              :file-list="fileList"
              show-file-list
              :on-success="handleavatarsuccess"
              :before-upload="beforeUpload"
              action="http://up.qiniup.com"
              :on-remove="handleRemove"
              multiple
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
      
       
       
        
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row >
     
          <!-- 用户名 -->

        <el-col  align="left" :span="20">
          <el-button size="mini"   type="primary" @click="goback" style="margin-right:50px"
            >返回</el-button
          >
             
        <el-select v-model="companyId"  style="margin-right:20px"  size="mini"  placeholder="请选择企业获取合同列表" @change="selectChange123" v-if="this.roleId">
            <el-option v-for="(item, index) in compayList" :key="index" :label="item.companyName" :value="item.companyId">
            </el-option>
          </el-select>
          <el-input placeholder="请输入合同编号"     v-model="queryParams.contractNo" size="mini" clearable  style="width:200px;margin-right:20px"
          ></el-input>
          <el-input placeholder="请输入签订人姓名" v-model="queryParams.signPerson" size="mini" clearable  style="width:200px;margin-right:20px"
          ></el-input>
          <!-- 手机号 -->
          <el-input
          placeholder="请输入手机号"
          v-model.trim="queryParams.tel"
          size="mini"
          style="width:200px"
          clearable
       
         
        ></el-input>
        <el-button type="info"  size="small" class="btn" @click="searchuser" style="margin-left:20px">查询</el-button>
        </el-col>
        <el-col align="right" :span="4">
        
       
        
       <el-button type="primary" size="small"  v-if="this.role&&this.companyId"   @click="addcontractInfo">创建合同</el-button>
        </el-col>
      
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
        ref="list" class="emp_table" v-if="list"  >

        <el-table-column
          align="center"
          prop="companyName"
          label="企业名称"
          :show-overflow-tooltip="true"
        />
        <el-table-column align="center" prop="contractNo" label="合同编号" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="title" label="合同标题" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="signDate" label="签订时间" :show-overflow-tooltip="true" />
   
        <el-table-column
          align="center"
          prop="tel"
          label="联系电话"
          :show-overflow-tooltip="true"
        />
    
        <el-table-column align="center" prop="status" label="合同状态" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.status==100" style="color:#000000">{{ scope.row.status |htzt }}</span>
            <span v-else-if="scope.row.status==400" style="color:#707070">{{ scope.row.status |htzt }}</span>
            <span v-else-if="scope.row.status==201" style="color:#F5A623">{{ scope.row.status |htzt }}</span>
            <span v-else-if="scope.row.status==300" style="color:#4a86e8">{{ scope.row.status |htzt }}</span>
                  <span v-else-if="scope.row.status==202" style="color: #60B572">{{ scope.row.status |htzt  }}</span>
                  <span v-else-if="scope.row.status==203" style="color: #D0021B">{{ scope.row.status |htzt  }}</span>
                  <span v-else>{{ scope.row.status |htzt  }}</span>
          </template>
        </el-table-column>
        
        <el-table-column align="center" label="操作"  width="300px">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="detail(scope.row)" v-if="role">
              提交合同
            </el-button>
            <el-button type="text" size="small" @click="gotoDetai(scope.row)">
              合同详情
            </el-button>
            <el-button v-if="role" type="text" size="small" @click="gotosz(scope.row)">
              生长记录
            </el-button>
            <el-button v-if="role" type="text" size="small" @click="gotoxs(scope.row)">
              销售记录
            </el-button>
            <el-button type="text" size="small" @click="updateManagerUser(scope.row)" v-if="role">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="sz(scope.row)" v-if="role">
               设置完成 
            </el-button>
            <el-button type="text" size="small" @click="delcontractInfo(scope.row)" v-if="role">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
        @pagination="getcontractInfo" />
    </div>
  </div>
</template>
  
<script>
import {
  getallcompany,
  contractReview,
  getcontractInfoDetail,
  getUploadToken,
  setcontractInfo,
  getcontractInfo,
  delcontractInfo,
  addcontractInfo,
  updatecontractInfo,
} from "../../request/http";

export default {
  data() {
    return {
      role:null,
      options: [],
      loading: true,//加载动画
      title: "",
      showDialog: false,
      list: "",
      total: 0,
      companyId:null,
      postData: {
        token: "", //上传凭证
        fid: "", //文件id
        fileDomain: "" //文件域名
      },
 
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyId:null,
        contractNo:"",
        signPerson:"",
        tel:""
      
       
      },
      fileList: [],
      compayList:[],
      dialogVisible: false, //显示添加或编辑框
      bmsContractInfoDto: {
        title:'',
        address:"",
        companyId:null,
        contractNo:'',
        contractPrice:'',
        contractType:'',
        fids:[],
        orderNum:"",
        signDate:"",
        signPerson:"",
        signType:"",
        tel:"",
        town:''
       
      },
     roleId:null,
      rules: {
        contractNo: [
          { required: true, message: "合同编号不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "合同标题不能为空", trigger: "blur" },
        ],
        contractPrice: [
          { required: true, message: "订单价格不能为空", trigger: "blur" },
        ],
        contractType: [
          { required: true, message: "合同类别不能为空", trigger: "blur" },
        ],
        fids: [
          { required: true, message: "合同图片不能为空", trigger: "blur" },
        ],
        signDate: [
          { required: true, message: "签订时间不能为空", trigger: "blur" },
        ],
        signPerson: [
          { required: true, message: "签订人姓名不能为空", trigger: "blur" },
        ],
       
        tel:
         [ {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: "请输入正确的手机号码",
          },]
        
        
      },
    };
  },

 async created() {
    
    this.role=localStorage.getItem('role')
    this.roleId =localStorage.getItem('role')
    if(this.roleId==100||this.roleId==0){
      this.roleId=true
    }
    else{
      this.roleId=false
    }
    if(this.role==1||this.role==100){
      this.role=true
    }else{
      this.role=false
    }
      console.log(this.role);
      let  role =localStorage.getItem('role')
      if(role==1){
        this.queryParams.companyId=Number(localStorage.getItem('companyId'))
      this.bmsContractInfoDto.companyId=Number(localStorage.getItem('companyId'))
      this.companyId=Number(localStorage.getItem('companyId'))
      this.getcontractInfo();
      }
      else if (role ==100||role==0){
        await this.getallcompany()
       this.companyId =this.compayList[0].companyId
       this.queryParams.companyId=this.compayList[0].companyId
       this.bmsContractInfoDto.companyId=this.compayList[0].companyId
       this.getcontractInfo();
        this.loading=false
      }
    
      
 
  
  },

  mounted() { 
 
  },
  methods: {
    
    selectChange123(value) {
      this.queryParams.companyId=Number(value)
      this.bmsContractInfoDto.companyId=Number(value)
       this.companyId=value
      console.log(this.queryParams.companyId,'查询公司id', this.bmsContractInfoDto.companyId,'添加公司id');
      this.getcontractInfo();
    },
    //全部企业
    async getallcompany() {
      let { data } = await getallcompany();
      // console.log(data);
      this.compayList = data.data;
      console.log(this.compayList);
    },
    gotosz(val){
     let companyId =Number( this.companyId)
     let contractId = val.contractId;
     this.$router.push({
        path: "/growth",
        query: {companyId, contractId },
      });
    },
    gotoxs(val){
      let companyId =Number( this.companyId)
     let contractId = val.contractId;
     this.$router.push({
        path: "/sale",
        query: {companyId, contractId },
      });
    },
    gotoDetai(val){
      
   if(val.status==100||val.status==201||val.status==203){
    //待审核
       
    let contractId = val.contractId;
    console.log(contractId);
      this.$router.push({
        path: "/contractReview",
        query: { contractId },
      });
   }
   else if(val.status==202||val.status==300||val.status==400){
    let contractId = val.contractId;
    let companyId =Number( this.companyId)
    this.$router.push({
        path: "/contractInfo",
        query: { companyId,contractId },
      });
   }
    },
    selectChange1(value) {
    this.bmsContractInfoDto.signDate=value
    console.log(this.bmsContractInfoDto.signDate);
  },
      //上传图片
      async beforeUpload(file) {
      let { data } = await getUploadToken();
      console.log(data);
      this.postData.token = data.data.token;
      this.postData.fid = data.data.fid;
      this.postData.fileDomain = data.data.fileDomain;
    },
    //上传图片成功回调函数
    handleavatarsuccess(response, file, fileList) {
      console.log(response, file, fileList);
      console.log( this.bmsContractInfoDto.fids);
      this.bmsContractInfoDto.fids.push(response.key);
      this.$message.success("上传图片成功");
    },
 //删除多张图片的一张
 handleRemove(file) {
      let key;
  
      file.key?  key = file.key: key = file.response.key
      
       
   let i =  this.bmsContractInfoDto.fids.indexOf(key);
     
      console.log(key,i);
      // console.log(i);
      //  this.bmsContractInfoDto.fids=  this.bmsContractInfoDto.fids.filter(item => item.fid!==key)
       this.bmsContractInfoDto.fids.splice(i, 1);
      console.log( this.bmsContractInfoDto.fids);
    
    },
      goback() {
    this.$router.go(-1)
  },
   //查询
    async searchuser(){
      this.queryParams.pageNum=1
      console.log(this.queryParams);
      const { data } = await getcontractInfo(this.queryParams);
      let list = data.data;
      console.log(list);
      if (data.code !== 0) {
        return this.$message.error("获取数据失败");
      } 
      this.total = list.total;
      this.list =  list.records;
      this.loading = false
    },
     //获取列表
     async getcontractInfo() {
     
      if (this.queryParams.contractNo=="" &&this.queryParams.signPerson=="" &&this.queryParams.tel=="") {
        console.log(this.queryParams,'无参数');
        let { data } = await getcontractInfo(this.queryParams);
      let list = data.data;
      console.log(list);
      this.list = list.records;

      this.total = list.total;
      this.loading = false
     
       
      }
      else {
    
      console.log(this.queryParams ,'有参数');
      let { data } = await getcontractInfo(this.queryParams);
      let list = data.data;
      console.log(list);
      this.list = list.records;

      this.total = list.total;
      this.loading = false
     
      }
     
    },
    async detail(row){
     
      let id = row.contractId;
      let { data } = await contractReview(id);
      if(  data.code == 0){
        this.$message({
          type: "success",
          message: "设置成功",
        });
      }
     else if( data.code!==0){
      this.$message({
          type: "error",
          message: data.msg,
        }) 
     }
       
       
         this.getcontractInfo();
    },
  

    async sz(row) {
      let id = row.contractId;
      let { data } = await setcontractInfo(id);
      if(  data.code == 0){
        this.$message({
          type: "success",
          message: "设置成功",
        });
      }
     else if( data.code!==0){
      this.$message({
          type: "error",
          message: data.msg,
        }) 
     }
       
       
         this.getcontractInfo();
    },
   

    // 打开添加的窗口
    addcontractInfo() {
      this.title = "创建合同";
      this.bmsContractInfoDto.companyId=Number( this.companyId)
      this.showDialog = true;
    },
    // 打开编辑类别的窗口
   async updateManagerUser(res) {
      // 根据获取id获取详情
      let { data } = await getcontractInfoDetail(res.contractId);
      console.log(data.data);
      let val =data.data
      this.title = "编辑合同";
     
      
      console.log(val.contractId);
      if (val.fids !== []) {
       
      var images =val.fids
      console.log(images);
      this.bmsContractInfoDto.title = val.title;
      this.bmsContractInfoDto.address = val.address;
      this.bmsContractInfoDto.contractNo = val.contractNo
   this.bmsContractInfoDto.fids =val.fids
    this.bmsContractInfoDto.contractId = val.contractId;
    this.bmsContractInfoDto.contractPrice = val.contractPrice;
    this.bmsContractInfoDto.companyId=val.companyId
      this.bmsContractInfoDto.contractType = val.contractType;
      this.bmsContractInfoDto.orderNum = val.orderNum;
   
   this.bmsContractInfoDto.signDate = val.signDate;
   this.bmsContractInfoDto.signPerson = val.signPerson;
   images,
   this.bmsContractInfoDto.signType = val.signType;
   this.bmsContractInfoDto.tel = val.tel;
   this.bmsContractInfoDto.town = val.town;
   for (let i = 0; i < images.length; i++) {
       this.fileList.push({
         url: "http://farmfile.uutele.com/" + images[i],
         key: images[i],
       });
     }

    } else {
      this.bmsContractInfoDto.address = val.address;
      this.bmsContractInfoDto.contractNo = val.contractNo
   this.bmsContractInfoDto.fids =val.fids
    this.bmsContractInfoDto.contractId = val.contractId;
    this.bmsContractInfoDto.contractPrice = val.contractPrice;
    this.bmsContractInfoDto.companyId=val.companyId
    
      this.bmsContractInfoDto.contractType = val.contractType;
      this.bmsContractInfoDto.orderNum = val.orderNum;
   
   this.bmsContractInfoDto.signDate = val.signDate;
   this.bmsContractInfoDto.signPerson = val.signPerson;
   this.bmsContractInfoDto.signType = val.signType;
   this.bmsContractInfoDto.tel = val.tel;
   this.bmsContractInfoDto.town = val.town;
     }
      this.showDialog = true;
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.bmsContractInfoDto.contractId) {
          let { data } = await updatecontractInfo(this.bmsContractInfoDto);
          if (this.bmsContractInfoDto.contractId && data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.getcontractInfo();

          this.showDialog = false;
        } else {
          let { data } = await addcontractInfo(this.bmsContractInfoDto);
          console.log(data);
          if (data.code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.getcontractInfo();

          this.showDialog = false;
        }
      });
    },
    //弹窗取消函数
    btnCancel() {
      this.bmsContractInfoDto =  {
        title:'',
        address:"",
        companyId:null,
        contractNo:'',
        contractPrice:'',
        contractType:'',
        fids:[],
        orderNum:"",
        signDate:"",
        signPerson:"",
        signType:"",
        tel:"",
        town:''
       
      },
      this.fileList=[]
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },

    async delcontractInfo(row) {
      try {
        await this.$confirm("确定要删除该合同吗");
        console.log(row);
        let contractId = row.contractId;
        let { data } = await delcontractInfo(contractId);

        if (data.code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.pageNum =
          this.queryParams.pageNum > totalPage ? totalPage : this.queryParams.pageNum;
        this.queryParams.pageNum =
          this.queryParams.pageNum < 1 ? 1 : this.queryParams.pageNum;

        this.getcontractInfo();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
  
<style scoped>

</style>
  